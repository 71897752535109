import { createContext, useContext } from "react";
import type { UserFragmentType } from "~/graphql/fragments/types";

export const AuthContext = createContext<{
  user: UserFragmentType | null | undefined;
  token?: string;
}>({
  user: undefined,
  token: undefined,
});

export const useUser = () => useContext(AuthContext);
